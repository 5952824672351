import moment from "moment";
import { Tooltip } from "primereact/tooltip";
import React, { useEffect, useState } from "react";
import { ConsultQueue, PaginatedConsultQueueList } from "../../../types/consult-api";

export function DaySchedule(props: { consults: PaginatedConsultQueueList | null }) {
    const [loading, setLoading] = useState(true);
    const startOfDay = moment().startOf("day");
    const timezone = moment.tz.guess();

    useEffect(() => {
        if (props.consults !== null) {
            setLoading(false);
        }
    }, [props.consults]);

    let today: ConsultQueue[] = [];
    let upcoming: ConsultQueue[] = [];
    if (props.consults?.results) {
        today = props.consults.results.filter((consult) => startOfDay.isSame(moment.utc(consult.appointmentScheduledStartsAt), "day"));
        upcoming = props.consults.results.filter((consult) => startOfDay.isBefore(moment.utc(consult.appointmentScheduledStartsAt), "day"));
    }

    const formatInteractionMode = (interactionMode: string): string => {
        // Replace underscores with spaces and capitalize each word
        return interactionMode.replace(/_/g, " ").replace(/\w\S*/g, (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    };

    const isPastEvent = (timestamp: string | null) => {
        if (!timestamp) {
            // Consider it not in the past if the timestamp is not available
            return false;
        }
        const now = moment();
        const scheduledTime = moment(timestamp);
        return scheduledTime.isBefore(now);
    };

    const ScheduleItem = ({ consult, dateTimeFormat }: { consult: ConsultQueue; dateTimeFormat: string }) => {
        const partnerName = consult.program.partnerName;
        const programName = consult.program.name;
        const type = formatInteractionMode(consult.interactionMode);
        const date = moment.utc(consult.appointmentScheduledStartsAt).tz(timezone).format(dateTimeFormat);
        const pastEvent = isPastEvent(consult.appointmentScheduledStartsAt);
        const consultGuid = consult.guid;

        return (
            <>
                <Tooltip target=".calendar-event" />
                <div
                    className="text-sm calendar-event"
                    data-pr-tooltip={`${date} - ${partnerName} ⋅ ${programName} - ${type}`}
                    data-pr-position="top"
                    style={{
                        display: "flex",
                        marginTop: "8px",
                        maxWidth: "350px",
                        whiteSpace: "nowrap",
                        color: pastEvent ? "#F44949" : "#26aebc",
                        cursor: "pointer",
                        overflow: "hidden",
                    }}
                    onClick={() => {
                        window.location.assign(`/consult/${consultGuid}`);
                    }}
                >
                    <div
                        style={{
                            flexShrink: 0,
                        }}
                    >
                        <span className={"font-semibold"}>{date}</span>{" "}
                    </div>
                    <div
                        style={{
                            flexShrink: 0,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                        }}
                    >
                        &nbsp;
                        {"- "}
                        {partnerName} ⋅ {programName}
                    </div>
                    <div
                        style={{
                            flexShrink: 1,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                        }}
                    >
                        &nbsp;
                        {"- "}
                        {type}
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className="todays-schedule">
            <div className="text-sm font-semibold">
                <span title={"todays-schedule"}>Today's Schedule</span>
            </div>
            {loading && (
                <div className="text-sm" style={{ marginTop: "8px" }}>
                    Loading...
                </div>
            )}
            {!loading && (
                <>
                    {today.length === 0 && (
                        <div
                            className="text-sm"
                            style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", marginTop: "8px", color: "#969696" }}
                        >
                            No scheduled consults
                        </div>
                    )}
                    {today.length > 0 && today.map((consult) => <ScheduleItem key={consult.guid} consult={consult} dateTimeFormat={"h:mm A"} />)}
                </>
            )}
            {!loading && (
                <>
                    <div className="text-sm font-semibold mt-4">
                        <span title={"todays-schedule"}>Upcoming</span>
                    </div>
                    {upcoming.length === 0 && (
                        <div
                            className="text-sm"
                            style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", marginTop: "8px", color: "#969696" }}
                        >
                            No scheduled consults
                        </div>
                    )}
                    {upcoming.length > 0 && upcoming.map((consult) => <ScheduleItem key={consult.guid} consult={consult} dateTimeFormat={"M/D h:mm A"} />)}
                </>
            )}
        </div>
    );
}
