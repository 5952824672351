import moment from "moment";
import { useRouter } from "next/router";
import { Toast } from "primereact/toast";
import { FC, useEffect, useRef, useState } from "react";
import { getNextUpConsults, getNextUpScheduledConsults } from "../../functions/getConsults";
import { useSession } from "../../functions/useSession";
import { AWSRumEventTypeEnum, recordEvent } from "../../public/scripts/rum";
import { PaginatedConsultQueueList } from "../../types/consult-api";

export interface withNextUpQueuePollerProps {
    nextUpConsults: PaginatedConsultQueueList | null;
    nextUpScheduledConsults: PaginatedConsultQueueList | null;
    newConsultIndicator: number;
    loading: { isTimerRefresh: boolean; nextUpConsults: boolean; nextUpScheduledConsults: boolean };
    methods: {
        setConsult: (value?: string[]) => void;
        setIsAsyncConsultsOnly: (value?: boolean) => void;
    };
}

const withNextUpQueuePoller = (Component: FC<any>) => (props: any) => {
    const [session] = useSession();
    const router = useRouter();
    const toast = useRef<Toast | null>(null);

    const isAsyncConsultsOnlyFilter = useRef<boolean | undefined>();
    const [nextUpConsults, setNextUpConsults] = useState<PaginatedConsultQueueList | null>(null);
    const [nextUpScheduledConsults, setNextUpScheduledConsults] = useState<PaginatedConsultQueueList | null>(null);
    const [newConsultIndicator, setNewConsultIndicator] = useState(-1);
    const [loading, setLoading] = useState({
        isTimerRefresh: true,
        nextUpConsults: false,
        nextUpScheduledConsults: false,
    });

    const consultFilter = useRef<string[] | undefined>();
    const errorMessage = useRef<string>("");

    const mapErrorMessageToTag = (msg: string) => {
        if (msg == "Unavailable Consult") {
            // Redirect to the queue after 2 seconds if the consult is no longer available
            setTimeout(() => {
                window.location.assign("/");
            }, 2000);
            return <span className={"text-sm"}>This consult is no longer available. Redirecting to the queue...</span>;
        } else {
            return msg;
        }
    };

    const handleError = (err: any) => {
        if (err.message !== errorMessage.current) {
            errorMessage.current = err.message;
            toast.current?.show({
                severity: "error",
                sticky: true,
                summary: "Uh oh!",
                detail: mapErrorMessageToTag(err.message),
                className: "error-toast",
            });
        }
    };
    const list = useRef<any>();
    list.current = { nextUpConsults, nextUpScheduledConsults };

    useEffect(() => {
        const newLoading = { ...loading };
        newLoading.nextUpConsults = false;
        setLoading(newLoading);
    }, [nextUpConsults]);

    const refresh = async (isTimerRefresh: boolean) => {
        setLoading({ isTimerRefresh, nextUpConsults: true, nextUpScheduledConsults: true });
        try {
            const [nextUpConsults, scheduledConsults] = await Promise.all([
                // @ts-ignore
                getNextUpConsults(consultFilter.current, undefined, undefined, undefined, isAsyncConsultsOnlyFilter.current)
                    .then((result) => {
                        setNextUpConsults(result);
                        return result;
                    })
                    .catch((err) => {
                        handleError(err);
                        setNextUpConsults({ next: "", previous: "", results: [] });
                        return null;
                    }),
                getNextUpScheduledConsults()
                    .then((result) => {
                        setNextUpScheduledConsults(result);
                        return result;
                    })
                    .catch((err) => {
                        handleError(err);
                        setNextUpScheduledConsults({ next: "", previous: "", results: [] });
                        return null;
                    }),
            ]);
            if (nextUpConsults) {
                if (nextUpConsults.results?.length == 0 && consultFilter.current && consultFilter.current.length > 0) {
                    handleError({ message: "Unavailable Consult" });
                }
            }
        } catch (err) {
            handleError(err);
            // Handle unexpected errors
        } finally {
            setLoading({ isTimerRefresh, nextUpConsults: false, nextUpScheduledConsults: false });
        }
    };

    useEffect(() => {
        const timer = setInterval(() => {
            if (session && moment.utc(session.expires) > moment()) {
                refresh(true);
            }
        }, 30 * 1000);
        return () => clearInterval(timer);
    }, [router.isReady, router.query, session]);

    useEffect(() => {
        if (router.isReady && session) {
            const consult = router.query.consult;
            if (consult) {
                consultFilter.current = Array.isArray(consult) ? consult : [consult];
            }
            refresh(true);
            recordEvent(AWSRumEventTypeEnum.ConsultDashboardEntered);
        }
    }, [router.isReady, router.query, session]);

    const pollerProps: withNextUpQueuePollerProps = {
        nextUpConsults,
        nextUpScheduledConsults,
        newConsultIndicator,
        loading,
        methods: {
            setConsult: (value) => {
                if (consultFilter.current !== value) {
                    consultFilter.current = value;
                    refresh(false);
                }
            },

            setIsAsyncConsultsOnly: (value) => {
                if (isAsyncConsultsOnlyFilter.current !== value) {
                    isAsyncConsultsOnlyFilter.current = value;
                    refresh(false);
                }
            },
        },
    };
    return (
        <>
            <Toast ref={toast} />
            <Component {...props} consultsPollerProps={pollerProps} />
        </>
    );
};

export default withNextUpQueuePoller;
