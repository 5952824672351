import moment from "moment";
import { useRouter } from "next/router";
import { Toast } from "primereact/toast";
import { FC, useEffect, useRef, useState } from "react";
import { getClinicianMaConsults, getMyConsults, getOtherConsults } from "../../functions/getConsults";
import { useSession } from "../../functions/useSession";
import { AWSRumEventTypeEnum, recordEvent } from "../../public/scripts/rum";
import { ConsultReasonForVisitEnum, PaginatedConsultQueueList } from "../../types/consult-api";
import { Episode } from "../../types/emr-api";

export interface withDefaultQueuePollerProps {
    myConsults: PaginatedConsultQueueList | null;
    otherConsults: PaginatedConsultQueueList | null;
    maConsults: PaginatedConsultQueueList | null;
    activeList: number;
    newConsultIndicator: number;
    loading: { isTimerRefresh: boolean; myConsults: boolean; otherConsults: boolean; maConsults: boolean };
    methods: {
        setActiveList: (tabIndex: number) => void;
        setConsult: (value?: string[]) => void;
        setIsAsyncConsultsOnly: (value?: boolean) => void;
        setPartner: (value?: string[]) => void;
        setProgram: (value?: string[]) => void;
        setReasonForVisit: (value?: ConsultReasonForVisitEnum[]) => void;
        setScore: (min?: number, max?: number) => void;
    };
    episode: Episode;
}

const withDefaultQueuePoller = (Component: FC<any>) => (props: any) => {
    const [session] = useSession();
    const sessionUser = session?.user as any;

    const router = useRouter();
    const toast = useRef<Toast | null>(null);

    const [myConsults, setMyConsults] = useState<PaginatedConsultQueueList | null>(null);
    const [otherConsults, setOtherConsults] = useState<PaginatedConsultQueueList | null>(null);
    const [maConsults, setMaConsults] = useState<PaginatedConsultQueueList | null>(null);
    const [episode, setEpisode] = useState(props.episode);
    const [activeList, _setActiveList] = useState(0);
    const [newConsultIndicator, setNewConsultIndicator] = useState(-1);
    const [loading, setLoading] = useState({
        isTimerRefresh: true,
        myConsults: false,
        otherConsults: false,
        maConsults: false,
    });

    const isAsyncConsultsOnlyFilter = useRef<boolean | undefined>();
    const consultFilter = useRef<string[] | undefined>();
    const partnerFilter = useRef<string[] | undefined>();
    const programFilter = useRef<string[] | undefined>();
    const reasonForVisitFilter = useRef<ConsultReasonForVisitEnum[] | undefined>();
    const minScoreFilter = useRef<number | undefined>();
    const maxScoreFilter = useRef<number | undefined>();
    const errorMessage = useRef<string>("");

    const setActiveList = (tabIndex: number) => {
        _setActiveList(tabIndex);
        setNewConsultIndicator(-1);
    };

    const mapErrorMessageToTag = (msg: string) => {
        if (msg == "Unavailable Consult") {
            // Redirect to the queue after 2 seconds if the consult is no longer availableWhen there are no more available consults remaining in the Consult Filter link, the Clinician is not sent back to the main queue
            setTimeout(() => {
                window.location.assign("/");
            }, 2000);
            return <span className={"text-sm"}>This consult is no longer available. Redirecting to the queue...</span>;
        } else {
            return msg;
        }
    };

    const handleError = (err: any) => {
        if (err.message !== errorMessage.current) {
            errorMessage.current = err.message;
            toast.current?.show({
                severity: "error",
                sticky: true,
                summary: "Uh oh!",
                detail: mapErrorMessageToTag(err.message),
                className: "error-toast",
            });
        }
    };

    const list = useRef<any>();
    list.current = { active: activeList, myConsults, otherConsults, maConsults };

    useEffect(() => {
        const newLoading = { ...loading };
        newLoading.myConsults = false;
        setLoading(newLoading);
    }, [myConsults]);

    useEffect(() => {
        const newLoading = { ...loading };
        newLoading.otherConsults = false;
        setLoading(newLoading);
    }, [otherConsults]);

    useEffect(() => {
        const newLoading = { ...loading };
        newLoading.maConsults = false;
        setLoading(newLoading);
    }, [maConsults]);

    const refresh = async (isTimerRefresh: boolean) => {
        const updateConsults = (
            index: number,
            oldConsults: PaginatedConsultQueueList | null,
            newConsults: PaginatedConsultQueueList,
            updateConsults: (arg: PaginatedConsultQueueList | null) => void,
            episode: (arg: Episode | null) => void = () => {}
        ) => {
            const hidden = index !== list.current.active;
            if (hidden) {
                const oldGuid = oldConsults?.results?.map((c) => c.guid) ?? [];
                const newGuid = newConsults?.results?.map((c) => c.guid) ?? [];
                const consultsChange = oldGuid.join(",") !== newGuid.join(",");
                if (consultsChange) {
                    setNewConsultIndicator(index);
                }
            }
            updateConsults(newConsults);
        };

        setLoading({ isTimerRefresh, myConsults: true, otherConsults: true, maConsults: true });
        try {
            const [myConsultsResult, otherConsultsResult] = await Promise.all([
                // @ts-ignore
                getMyConsults(
                    consultFilter.current,
                    partnerFilter.current,
                    programFilter.current,
                    reasonForVisitFilter.current,
                    isAsyncConsultsOnlyFilter.current,
                    minScoreFilter.current,
                    maxScoreFilter.current
                )
                    .then((result) => {
                        updateConsults(0, list.current.myConsults, result, setMyConsults);
                        return result;
                    })
                    .catch((err) => {
                        handleError(err);
                        setMyConsults({ next: "", previous: "", results: [] });
                        return null;
                    }),
                // @ts-ignore

                getOtherConsults(
                    consultFilter.current,
                    partnerFilter.current,
                    programFilter.current,
                    reasonForVisitFilter.current,
                    isAsyncConsultsOnlyFilter.current,
                    minScoreFilter.current,
                    maxScoreFilter.current
                )
                    .then((result) => {
                        updateConsults(1, list.current.otherConsults, result, setOtherConsults);
                        return result;
                    })
                    .catch((err) => {
                        handleError(err);
                        setOtherConsults({ next: "", previous: "", results: [] });
                        return null;
                    }),
                // @ts-ignore
                getClinicianMaConsults()
                    .then((result) => {
                        updateConsults(2, list.current.maConsults, result, setMaConsults, setEpisode);
                        return result;
                    })
                    .catch((err) => {
                        handleError(err);
                        setMyConsults({ next: "", previous: "", results: [] });
                        return null;
                    }),
            ]);

            // Additional logic after both async functions have completed
            if (myConsultsResult && otherConsultsResult) {
                if (
                    myConsultsResult.results?.length == 0 &&
                    otherConsultsResult.results?.length == 0 &&
                    consultFilter.current &&
                    consultFilter.current.length > 0
                ) {
                    handleError({ message: "Unavailable Consult" });
                }
            }
        } catch (err) {
            handleError(err);
        } finally {
            setLoading({ isTimerRefresh, myConsults: false, otherConsults: false, maConsults: false });
        }
    };

    useEffect(() => {
        const timer = setInterval(() => {
            if (session && moment.utc(session.expires) > moment()) {
                refresh(true);
            }
        }, 30 * 1000);
        return () => clearInterval(timer);
    }, [router.isReady, router.query, session]);

    useEffect(() => {
        if (!router.isReady) {
            return;
        }
        if (session) {
            const consult = router.query.consult;
            if (consult) {
                consultFilter.current = Array.isArray(consult) ? consult : [consult];
            }
            refresh(true);
            recordEvent(AWSRumEventTypeEnum.ConsultDashboardEntered);
        }
        if (sessionUser && router.query.shortCode) {
            recordEvent(AWSRumEventTypeEnum.ConsultDashboardFiltered, {
                clinicianGuid: sessionUser.clinician.guid,
                shortCode: router.query.shortCode,
            });
        }
    }, [router.isReady, router.query, session]);

    const pollerProps: withDefaultQueuePollerProps = {
        myConsults,
        otherConsults,
        maConsults,
        activeList,
        newConsultIndicator,
        loading,
        episode,

        methods: {
            setActiveList,

            setConsult: (value) => {
                if (consultFilter.current !== value) {
                    consultFilter.current = value;
                    refresh(false);
                }
            },

            setIsAsyncConsultsOnly: (value) => {
                if (isAsyncConsultsOnlyFilter.current !== value) {
                    isAsyncConsultsOnlyFilter.current = value;
                    refresh(false);
                }
            },

            setPartner: (value) => {
                if (partnerFilter.current !== value) {
                    partnerFilter.current = value;
                    refresh(false);
                }
            },

            setProgram: (value) => {
                if (programFilter.current !== value) {
                    programFilter.current = value;
                    refresh(false);
                }
            },

            setReasonForVisit: (value) => {
                if (reasonForVisitFilter.current !== value) {
                    reasonForVisitFilter.current = value;
                    refresh(false);
                }
            },

            setScore: (min?: number, max?: number) => {
                if (minScoreFilter.current !== min) {
                    minScoreFilter.current = min;
                }
                if (maxScoreFilter.current !== max) {
                    maxScoreFilter.current = max;
                }
                refresh(false);
            },
        },
    };
    return (
        <>
            <Toast ref={toast} />
            <Component {...props} consultsPollerProps={pollerProps} />
        </>
    );
};

export default withDefaultQueuePoller;
